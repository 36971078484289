import React from "react";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import { Api } from "../../config/Api";
import DataService from "../../config/DataService";
import Index from "../Index";

const ForgotPassword = () => {
  const navigate = Index.useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({});


  const submit = async (data) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("email", data.email);
    await DataService.post(Api.User.forgotPassword, urlencoded).then((res) => {
      if (res.data.status == 201) {
        toast.success(res.data.message)
        setTimeout(() => {
          navigate(`/otp/${res.data.data._id}`, { state: { otp: `${res?.data.data?.otp}`}});
        }, 3000);
      }
    });
  };

  return (
    <div>
      <Index.Box className="admin-login-main-flex">
        <Index.Box className="admin-login-left-main set-login-box-back">
          <Index.Box className="login-left-bg">
            <img src={Index.Png.ss_logo} className=""></img>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-login-right-main">

          <Index.Box className="admin-login-box">
            <Index.Box className="admin-login-main">
              <form className="form-content" onSubmit={handleSubmit(submit)}>
                <Index.Box className="admin-login-inner set-login-box">
                  <Index.Typography
                    component="h2"
                    variant="h2"
                    className="admin-wel-text"
                  >
                    Forgot Password!
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="admin-sign-para common-para"
                  >
                    {/* Please enter your Email! */}
                  </Index.Typography>
                  <Index.Box className="input-box">
                    <Index.FormHelperText className="form-lable">
                      Email
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        name="email"
                        {...register("email", {
                          required: "Email is Required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address",
                          },
                        })}
                        error={Boolean(errors.email)}
                        helperText={errors?.email?.message}
                      />
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="btn-main-primary login-btn-main">
                    <Index.Button
                      className="btn-primary login-btn"
                      type="submit"
                    >
                      Forgot Password
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </form>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <ToastContainer />
      </Index.Box>
    </div>
  );
};

export default ForgotPassword;
