import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;

{
  // console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const setHeadersWithAccessToken = (token) => {
  //console.log(token, "darshit");
  axios.defaults.headers.post["auth"] = token;
};

const setHeadersWithContentType = () => {
  axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded";
};

const setGetHeadersWithAccessToken = (token) => {
  console.log("Admin Token = ", token);
  axios.defaults.headers.get["auth"] = token;
};

const handleError = (error) => {
  //console.log(error,'db-error');
  for (const key in error) {
    if (Object.hasOwnProperty.call(error, key)) {
      const element = error["response"];
      console.log("element,4444");
      return { message: element?.data?.message, status: element?.status };
    }
  }
};

export const signupApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/user/register`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const loginApi = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/admin/login`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getAllUserApi = (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/users`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getTransactionApi = (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/user/get-transaction-history`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addEditGameApi = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/admin/add-editgame`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getAllGamesApi = () => {
  setHeadersWithContentType();
  return axios
    .get(`${BASE_URL}/common/allgamelist`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const GamewiseBetListApi = (token) => {
  setHeadersWithContentType();
  return axios
    .get(`${BASE_URL}/admin/gamewise-bets`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addEditGameBetApiHandler = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/addedit-gamebet`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addEditPrivacyPolicyApi = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/add-edit-privacypolicy`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addEditTermsAndConditionApi = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/add-edit-termsandcondition`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const cmsDetailsApi = () => {
  setHeadersWithContentType();
  return axios
    .get(`${BASE_URL}/common/cms`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const activeDeactiveUserApi = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/admin/deactivateuser`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addUpdateGameRulesApi = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/add-gameWise-rule`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getGameRulesApi = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/common/game-rule-by-id-or-all`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const deleteGameRulesApi = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/gamewise-rule-remove`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};
export const deleteGameWiseBetApi = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/remove-gamewise-bet`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};
export const deleteGameApi = (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/remove-game`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const AllRoomListApi = (params, token) => {
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/getAllRoom`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const adminUserChatApi = (params) => {
  // setGetHeadersWithAccessToken(token)
  return axios
    .post(`${BASE_URL}/user/chatroom`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const walletlistApi = (token) => {
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/wallet/allWallets`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getUserWiseBetListApi = (token) => {
  setGetHeadersWithAccessToken(token);

  return axios
    .get(`${BASE_URL}/admin/allbetlist`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const callReqApi = (token) => {
  setGetHeadersWithAccessToken(token);

  return axios
    .get(`${BASE_URL}/admin/callrequest-list`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const dailyBonusApi = (token) => {
  setGetHeadersWithAccessToken(token);

  return axios
    .get(`${BASE_URL}/admin/wallet/addDailyBonus`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getBalanceReportApi = (params, token) => {
  setGetHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/admin-balance-report`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const editProfileApi = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/admin/edit-admin`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};
