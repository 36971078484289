import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TablePagination,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  deleteGameWiseBet,
  gameWiseBetList,
} from "../../../../../redux/auth/action";
import Index from "../../../../Index";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Api } from "../../../../../config/Api";
import DataService from "../../../../../config/DataService";
import { ToastContainer } from "react-toastify";
import Swal from "sweetalert2";

const GameWiseBet = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [gameWisebetDetails, setGameWiseBetDetails] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const GameWiseBetList = useSelector((state) => {
    return state.adminReducer.gameWiseBet;
  });
  console.log(gameWisebetDetails, "GameWiseBetList");
  const token = useSelector((state) => {
    return state.adminReducer.Token;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const getGameWiseBetList = () => {
    dispatch(gameWiseBetList(token, navigate));
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const modifyData = () => {
    const totals = [];
    GameWiseBetList.forEach((item) => {
      const obj = totals.find((row) => row?.gameId === item?.gameId?._id);
      console.log(obj, "obj");
      if (obj) {
        let res = {
          id: item?._id,
          maxPlayers: item?.maxPlayers,
          betAmount: item?.betAmount,
          winningPrize: item?.winningPrize,
        };
        obj?.data.push(res);
      } else {
        let betData = {
          gameId: item?.gameId._id,
          name: item?.gameId?.gameName,
          data: [
            {
              id: item?._id,
              maxPlayers: item?.maxPlayers,
              betAmount: item?.betAmount,
              winningPrize: item?.winningPrize,
            },
          ],
        };
        console.log(betData, "betData", item);
        totals?.push(betData);
      }
    });
    console.log(GameWiseBetList, "GameWiseBetList12");
    console.log(totals, "totals");

    setGameWiseBetDetails(totals);
  };

  useEffect(() => {
    getGameWiseBetList();
  }, []);

  useEffect(() => {
    modifyData();
  }, [GameWiseBetList]);

  const deletehandler = async (id) => {
    console.log(id, "delete id");
    Swal.fire({
      title: "",
      text: "Are you sure you want to delete this GameBet ?",
      icon: "error",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const urlencoded = new URLSearchParams();
        urlencoded.append("id", id);
        dispatch(deleteGameWiseBet(urlencoded, token));
      }
    });
  };

  console.log(gameWisebetDetails, "gameWisebetDetails");
  return (
    <>
      <Index.Box className="dashboard-content">
        <ToastContainer />

        <Index.Box className="user-list-flex">
          <Index.Typography
            className="admin-page-title user-list-page-title"
            component="h2"
            variant="h2"
          >
            Game Wise Bet
          </Index.Typography>
          <Index.Box className="userlist-btn-flex">
            <Index.Box></Index.Box>

            <Index.Box className="flex-all user-list-inner-flex">
              <Index.Box className="adduser-btn-main btn-main-primary">
                <Index.Button
                  className="adduser-btn btn-primary"
                  onClick={() => navigate("/admin/game-wise-bet/add")}
                >
                  <img
                    src={Index.Svg.plus}
                    className="plus-icon"
                    alt="plus icon"
                  />
                  Add Bet
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        {gameWisebetDetails?.length > 0 ? (
          <>
            {gameWisebetDetails
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row, index) => {
                console.log(gameWisebetDetails, 134);
                return (
                  <Accordion
                    expanded={expanded === `panel${index}`}
                    onChange={handleChange(`panel${index}`)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography sx={{ width: "33%", flexShrink: 0 }}>
                        {row?.name ? row.name : "-"}
                      </Typography>
                      <Typography sx={{ color: "text.secondary" }}></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Index.Box className="admin-dashboard-list-row">
                        <Index.Box sx={{ width: 1 }} className="grid-main">
                          <Index.Box
                            display="grid"
                            className="display-row"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                          >
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 12",
                                md: "span 12",
                                lg: "span 12",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="admin-dash-box">
                                <Index.Box className="userlist-table-main  page-table-main gamewisebet-table">
                                  <Index.TableContainer
                                    component={Index.Paper}
                                    className="table-container"
                                  >
                                    <Index.Table
                                      sx={{ minWidth: 650 }}
                                      aria-label="simple table"
                                      className="table"
                                    >
                                      <Index.TableHead className="table-head">
                                        <Index.TableRow className="table-row">
                                          <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                          >
                                            Max Players
                                          </Index.TableCell>

                                          <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                          >
                                            BET
                                          </Index.TableCell>

                                          <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                            align="center"
                                          >
                                            Action
                                          </Index.TableCell>
                                        </Index.TableRow>
                                      </Index.TableHead>
                                      <Index.TableBody className="table-body">
                                        {row.data.map((item, i) => {
                                          console.log(row, "2023");
                                          return (
                                            <>
                                              <Index.TableRow
                                                key={row._id}
                                                sx={{
                                                  "&:last-child td, &:last-child th":
                                                    {
                                                      border: 0,
                                                    },
                                                }}
                                              >
                                                <Index.TableCell
                                                  component="td"
                                                  variant="td"
                                                  scope="row"
                                                  className="table-td"
                                                >
                                                  {item?.maxPlayers}
                                                </Index.TableCell>
                                                <Index.TableCell
                                                  component="td"
                                                  variant="td"
                                                  className="table-td"
                                                >
                                                  <Index.Box>
                                                    <Index.Button>
                                                      {item?.betAmount} 💸
                                                    </Index.Button>
                                                  </Index.Box>
                                                </Index.TableCell>

                                                <Index.TableCell
                                                  component="td"
                                                  variant="td"
                                                  className="table-td"
                                                >
                                                  <Index.Button
                                                    onClick={() =>
                                                      deletehandler(item?.id)
                                                    }
                                                  >
                                                    <img
                                                      src={Index.Svg.trash}
                                                    ></img>
                                                  </Index.Button>
                                                </Index.TableCell>
                                              </Index.TableRow>
                                            </>
                                          );
                                        })}
                                      </Index.TableBody>
                                    </Index.Table>
                                  </Index.TableContainer>
                                </Index.Box>
                              </Index.Box>{" "}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </AccordionDetails>
                  </Accordion>
                );
              })}

            <Index.Box className="pagination-main">
              <TablePagination
                component="div"
                rowsPerPageOptions={[gameWisebetDetails?.length % 10]}
                count={gameWisebetDetails?.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) =>
                  `Records ${from}-${to} of ${count}`
                }
              />
            </Index.Box>
          </>
        ) : (
          <Accordion
          // onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                No Record Found
              </Typography>
              <Typography sx={{ color: "text.secondary" }}></Typography>
            </AccordionSummary>
          </Accordion>
        )}
        <ToastContainer />
      </Index.Box>
    </>
  );
};

export default GameWiseBet;
