
import './App.css';
import Routers from './routes/Routes';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/admin.css';
import './assets/css/responsive.css';
import socketIO from "socket.io-client";
import { SocketProvider } from './SocketContext';



function App() {
  return (
    <div className="App">
       <SocketProvider>
      <Routers/>
      </SocketProvider>
    </div>
  );
}

export default App;
