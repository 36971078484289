import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendIcon from "@mui/icons-material/Send";
import Index from "../../../../component/Index";
import { useSocket } from "../../../../SocketContext";
import socketIO from "socket.io-client";
import { adminUserChat } from "../../../../redux/auth/action";

// admin.supersagaofficial.com
const Chat = () => {
  const socket = useSocket();
  console.log("55555", socket)
  // const socket = socketIO.connect(process.env.REACT_APP_SOCKET_URL);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();
  let roomId = params?.roomId;
  let userId = params?.userId;
  let username = localStorage.getItem("userName");
  const [adminprofile, setAdminProfile] = useState("");
  const [userprofile, setUserProfile] = useState("");

  const adminData = useSelector((state) => {
    return state.adminReducer.login;
  });

  console.log("adminData", adminData)

  const chatData = useSelector((state) => {
    return state.adminReducer.chatData;
  });

  console.log(chatData , ":chatData")

  const messagesEndRef = useRef(null);
  const [message, setMessage] = useState("");
  const [chat, setChat] = useState(chatData?.messages);
  console.log(chat,'maulik37')

  const adminChat = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("from", adminData?.adminId);
    urlencoded.append("to", userId);
    dispatch(adminUserChat(urlencoded, navigate, userId));
  };

  const executeScroll = () => {
    messagesEndRef.current.scrollIntoView();
  };

  const SendMessage = () => {
    console.log("message sent");
    if (message != "") {
      let data = {
        from: "admin",
        message: message,
        roomID: roomId,
      };
      console.log(socket,'maulik57')
      socket.emit("Message", data );
      // setChat((e)=>([...e,data]))
    }

    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    executeScroll();
    setMessage("");
  };

  useEffect(() => {
    adminChat();
    adminChat();
    executeScroll();
    // setAdminProfile(`${process.env.REACT_APP_BASE_URL}/Images/${adminData?.profile}`);
    // if(location?.state?.user?.profile){
    //   setUserProfile(`${process.env.REACT_APP_BASE_URL}/Images/${location?.state?.user?.profile}`)
    // }
  },[]);

  useEffect(() => {
    let data = {
      roomID: roomId,
    };
    socket.emit("JoinChatRoom", data);
    // socket.on("Message", (data) => {
    //   console.log(data, 67);
    //   setChat(data);
    // });
  }, []);

  useEffect(() => {
    socket.on("Message", (data) => {
      console.log(data, "hellllloo");
      setChat(data);
    });
  }, []);

  const backToUser = () => {
    // socket.emit("ChatRoomLeave", roomId);
    localStorage.removeItem("userName");
    navigate("/admin/user-list");
  };

  return (
    <>
      <Index.Box className="chat-header">
        <Index.Box className="chat-heading">
          <Index.Box className="back-button">
            <Index.Button variant="outlined" onClick={() => backToUser()}>
              <ArrowBackIcon />
            </Index.Button>
          </Index.Box>
          <Index.Typography variant="h6">{username}</Index.Typography>
        </Index.Box>
      </Index.Box>
      <Index.Box className="chat-bord">
        <div class="container " style={{ border: "0.5px solid gray" }}>
          <div
            style={{ backgroundColor: "#1a243b70", border: "0.5px solid gray" }}
          >
            <div class="chatbox">
              {chat?.map((row) => {
                return (
                  <>
                    {row?.from == "admin" ? (
                      <div class="chat chat-right-msg">
                        {/* ${process.env.REACT_APP_BASE_URL}/Images/${admin?.profile} */}
                        <img
                          src={adminprofile ? adminprofile : Index.Png.usericon}
                          alt=""
                        />
                        <p class="msg">
                          <ion-icon
                            name="caret-back-outline"
                            class="left right-icon"
                          >
                            {" "}
                          </ion-icon>
                          {row.message}
                        </p>
                      </div>
                    ) : (
                      <div class="chat chat-left-msg">
                        <img
                          src={userprofile != '' ? userprofile : Index.Png.usericon}
                          alt=""
                        />
                        <p class="msg">
                          <ion-icon
                            name="caret-back-outline"
                            class="left right-icon"
                          >
                            {" "}
                          </ion-icon>
                          {row.message}
                        </p>
                      </div>
                    )}
                  </>
                );
              })}
              <div ref={messagesEndRef} className="scrolldiv" />
            </div>
          </div>
          <Index.Box className="bottom-sent-chat">
            <Index.Box className="flex-item">
              <Index.TextField
                className="chat-input"
                variant="outlined"
                value={message}
                onChange={(e) => {
                  if (/^(?![\s-])[\w\s-]+$/.test(e.target.value)) {
                    setMessage(e.target.value)
                  } else {
                    setMessage('')
                  }
                }}
              />
              <Index.Button
                sx={{ backgroundColor: "green", color: "white" }}
                variant="contained"
                onClick={() => SendMessage()}
              >
                <SendIcon />
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </div>
      </Index.Box>
    </>
  );
};

export default Chat;
