import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import AddUser from "../container/pages/admin/userlist/adduser/AddUser";
import Dashboard from "../container/pages/admin/dashboard/Dashboard";
import Login from "../container/auth/Login";
import EditProfile from "../container/pages/admin/editprofile/EditProfile";
import UserList from "../container/pages/admin/userlist/UserList";
import Home from "../container/pages/admin/home/Home";

import Otp from "../container/auth/Otp";
import ForgotPassWord from "../container/auth/ForgotPassword";
import ResetPassword from "../container/auth/ResetPassword";
import GameWiseBet from "../container/pages/admin/game/gameWiseBet/GameWiseBet";
import AboutUs from "../container/pages/admin/cms/Aboutus";
import PrivacyPolicy from "../container/pages/admin/cms/PrivacyPolicy";
import TermsAndCondition from "../container/pages/admin/cms/Terms&Condition";
import BetList from "../container/pages/admin/betlist/BetList";
import AddGameBet from "../container/pages/admin/game/gameWiseBet/AddGameBet";
import GameListPage from "../container/pages/admin/game/GameList";
import AddGame from "../container/pages/admin/game/AddGame";
import RoomsList from "../container/pages/admin/room/RoomsList";
import { useSelector } from "react-redux";
import Chat from "../container/pages/admin/chat/Chat";
import GameWiseRules from "../container/pages/admin/game/gameRule/GameWiseRules";
import AddGameRules from "../container/pages/admin/game/gameRule/AddGameRules";
import PaymentList from "../container/pages/admin/payment/PaymentList";
import RequestList from "../container/pages/admin/requestList/RequestList";
import UserDetails from "../container/pages/admin/userlist/userDetail/UserDetails";
import Report from "../container/pages/admin/Report/Report";
import BalanceReport from "../container/pages/admin/Report/BalanceReport";
import TransactionList from "../container/pages/admin/transctionhistroy/TransactionList";

const Routers = () => {
  const token = useSelector((state) => {
    return state.adminReducer.Token;
  });

  return (
    <BrowserRouter>
      <Routes>
        <Route index path="/" element={<Login />} />
        <Route path="otp/:id" element={<Otp />} />
        <Route path="forgot-password" element={<ForgotPassWord />} />
        <Route path="reset-password" element={<ResetPassword />} />
        {token ? (
          <>
            <Route path="admin" element={<Dashboard />}>
              <Route path="dashbord" element={<Home />} />
              <Route path="profile" element={<EditProfile />} />
              <Route path="room-list" element={<RoomsList />} />
              <Route path="add-user" element={<AddUser />} />
              <Route path="edit-profile" element={<EditProfile />} />
              <Route path="user-list" element={<UserList />} />
              <Route path="transction-histroy" element={<TransactionList />} />
              <Route path="game-list" element={<GameListPage />} />
              <Route path="game-list/:slug" element={<AddGame />} />
              <Route path="game-wise-bet" element={<GameWiseBet />} />
              <Route path="game-wise-bet/:slug" element={<AddGameBet />} />
              <Route path="game-rules" element={<GameWiseRules />} />
              <Route path="game-rules/:slug" element={<AddGameRules />} />
              <Route path="game-rules/:slug/:id" element={<AddGameRules />} />
              <Route path="betList" element={<BetList />} />
              <Route path="chat/:roomId/:userId" element={<Chat />} />
              <Route path="payment-list" element={<PaymentList />} />
              <Route path="request-list" element={<RequestList />} />
              <Route path="user-details/:id" element={<UserDetails />} />

              <Route path="cms">
                <Route path="aboutus" element={<AboutUs />} />
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                <Route
                  path="terms-and-condition"
                  element={<TermsAndCondition />}
                />
              </Route>
              <Route path="report">
                <Route path="game-report" element={<Report />} />
                <Route path="balance-report" element={<BalanceReport />} />
              </Route>
            </Route>{" "}
          </>
        ) : (
          <Route path="*" element={<Navigate to="/" replace={true} />} />
        )}
      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
