import React, { useState } from "react";
import Index from "../../../../component/Index";
import { Link } from "react-router-dom";
import { TextField } from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const Submit = () => {
  alert("submitted!");
};

export default function AboutUs() {
  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Box className="user-list-flex">
          <Index.Typography
            className="admin-page-title user-list-page-title"
            component="h2"
            variant="h2"
          >
            About Us
          </Index.Typography>
        </Index.Box>
        <Index.Box>
    
          <Index.Box className="input-box modal-input-box">
          <Index.FormHelperText className="form-lable">
              Description
            </Index.FormHelperText>
            <Index.Box className="set-ck-editor">
          <CKEditor
            editor={ClassicEditor}
            data=""
            onReady={(editor) => {
              // You can store the "editor" and use when it is needed.
              console.log("Editor is ready to use!", editor);
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              console.log({ event, editor, data });
            }}
            onBlur={(event, editor) => {
              console.log("Blur.", editor);
            }}
            onFocus={(event, editor) => {
              console.log("Focus.", editor);
            }}
          />
          </Index.Box>
          </Index.Box>
          <Index.Box className="save-btn-main border-btn-main">
              <Index.Button className="save-user-btn border-btn">
                <img src={Index.Svg.save} className="user-save-icon"></img>Save
              </Index.Button>
            </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
