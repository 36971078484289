import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllGameList } from "../../../../redux/auth/action";
import Index from "../../../Index";
import MessageIcon from "@mui/icons-material/Message";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";

import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

export default function Report() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const AllGameList = useSelector((state) => {
    return state.adminReducer.AllGames;
  });

  const currentdate = moment(new Date()).format("YYYY-MM-DD");

  const [avgPlayer, setAvgPlayer] = useState({});
  const [totalActivePlayer, setTotalActivePlayer] = useState([]);
  const [selectGame, setSelectGame] = useState(AllGameList[AllGameList.length -1]?._id);
  const [selectDate, setSelectDate] = useState({
    startDate: currentdate,
    endDate: currentdate,
  });

  const handleChange = (event) => {
    setSelectGame(event.target.value);
  };

  const getAvgPlayer = async (value) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("date", value);
    await DataService.post(Api.User.avgActiveGamePlayer, urlencoded).then(
      (res) => {
        console.log(res.data, 119);
        setAvgPlayer(res.data);
      }
    );
  };

  const handleDate = (value) => {
    let startDate = moment(value[0].$d).format("YYYY-MM-DD");
    let endDate = moment(value[1].$d).format("YYYY-MM-DD");

    let data = {
      startDate,
      endDate,
    };
    console.log(data);

    setSelectDate(data);
  };

  const getTotalMatchPlayer = async (value) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("from", selectDate?.startDate);
    urlencoded.append("to", selectDate?.endDate);
    urlencoded.append("gameId", selectGame);
    await DataService.post(Api.User.totalactive, urlencoded).then((res) => {
      setTotalActivePlayer(res.data.data);
    });
  };

  const getAllGameDetail = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("gameId", "");
    dispatch(getAllGameList(urlencoded, navigate));
  };

  useEffect(() => {
    getAllGameDetail();

    getAvgPlayer(currentdate);
  }, []);

  useEffect(() => {
    getTotalMatchPlayer();
  }, [selectDate, selectGame]);

  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Box className="user-list-flex">
          <Index.Typography
            className="admin-page-title user-list-page-title"
            component="h2"
            variant="h2"
          >
            Report
          </Index.Typography>
          <Index.Box className="userlist-btn-flex"></Index.Box>
        </Index.Box>
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column "
              >
                <Index.Box className="admin-dash-box">
                  <Index.Box className="flex-all table-header admin-dashboard-table-row">
                    <Index.Box className="admin-sub-title-main admin-sub-title-main2">
                      <Index.Typography
                        className="admin-sub-title"
                        component="h3"
                        variant="h3"
                      >
                        Active Average players
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="view-btn-main border-btn-main">
                      <Index.Box className="view-btn-main  view-btn-main2 inner-date border-btn-main both-date-main both-date-main2">
                        <Index.Box className="dropdown-box  dropdown-box2 box-set-game  input-box modal-input-box">
                          <Index.FormHelperText className="form-lable">
                            Select Game
                          </Index.FormHelperText>
                          <Index.FormControl className="form-control">
                            <Index.Select
                              className="dropdown-select dropdown-select2"
                              value={selectGame}
                              label="Age"
                              onChange={handleChange}
                            >
                              {AllGameList?.map((data) => {
                                return (
                                  <Index.MenuItem value={data?._id}>
                                    {data.gameName}
                                  </Index.MenuItem>
                                );
                              })}
                            </Index.Select>
                          </Index.FormControl>
                        </Index.Box>
                        <Index.Box className="input-box box-set-date">
                          <Index.FormHelperText className="form-lable">
                            Select Date
                          </Index.FormHelperText>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                              components={["SingleInputDateRangeField"]}
                            >
                              <DateRangePicker
                                className="set-date-btn date-pick-inner"
                                slots={{ field: SingleInputDateRangeField }}
                                onChange={(e) => handleDate(e)}
                                format="DD/MM/YYYY"
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="page-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Game
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Days
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Bet
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Active Player
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Average Players
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {totalActivePlayer.length > 0 ? (
                            totalActivePlayer.map((row) => (
                              <Index.TableRow
                                key={row.name}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                >
                                  {row.gameName ? row.gameName : "-"}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                >
                                  {row.days ? row.days : 0}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {row.betAmount ? row.betAmount : 0}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {row.activeUsers ? row.activeUsers : 0}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {row.averagePlayers ? row.averagePlayers : 0}
                                </Index.TableCell>
                              </Index.TableRow>
                            ))
                          ) : (
                            <Index.TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                No Record Found
                              </Index.TableCell>
                            </Index.TableRow>
                          )}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column table-gap"
              >
                <Index.Box className="admin-dash-box">
                  <Index.Box className="flex-all  res-set-date-text admin-dashboard-table-row">
                    <Index.Box className="admin-sub-title-main admin-sub-title-main2">
                      <Index.Typography
                        className="admin-sub-title"
                        component="h3"
                        variant="h3"
                      >
                        Matched Active Average players
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="view-btn-main inner-date border-btn-main">
                      {/* <Index.Button
                        className="view-order-btn border-btn"
                        btnLabel="View Orders"
                      >
                        View Orders
                      </Index.Button> */}
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoItem>
                          <DatePicker
                            className="set-date-btn date-pick-inner"
                            format="DD/MM/YYYY"
                            onChange={(e) => {
                              // handleSearch(moment(e.$d).format("YYYY-MM-DD"));
                              getAvgPlayer(moment(e.$d).format("YYYY-MM-DD"));
                            }}
                          />
                        </DemoItem>
                      </LocalizationProvider>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="page-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Total Player
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Total Matched Player
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Active Average Player
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          <Index.TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <Index.TableCell
                              component="td"
                              variant="td"
                              scope="row"
                              className="table-td"
                            >
                              {avgPlayer.totalactiveuser
                                ? avgPlayer.totalactiveuser
                                : "0"}
                            </Index.TableCell>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                            >
                              {avgPlayer.totalgamepayedbyuser
                                ? avgPlayer.totalgamepayedbyuser
                                : "0"}
                            </Index.TableCell>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                            >
                              {avgPlayer.avaerage ? avgPlayer.avaerage : "0"}
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
