import React, { useState, useEffect } from "react";
import Index from "../../../../../component/Index";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";

const WinLoseratio = ({ id }) => {
  const [winloseratio, setWinLoseRatio] = useState({});

  const getWinLoseRatio = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("userId", id);
    await DataService.post(Api.User.WinLoseRatio, urlencoded).then((res) => {
      setWinLoseRatio(res.data.data);
    });
  };

  useEffect(() => {
    getWinLoseRatio();
  }, [id]);
  return (
    <div className="win-lose-container">
      {/* User Statistics Section */}
      <Index.Box className="user-stats">
        <Index.Box className="win-lose-main">
          <Index.Box className="card-main-head">
            Win-lose Ratio Till Date
          </Index.Box>
          <Index.Box className="card-main-body">
            <Index.Box className="card-total">
              <Index.Typography className="left-name">
                Total Games
              </Index.Typography>
              <Index.Typography className="right-data">
                {winloseratio?.totalGamesPlayed || 0}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="card-total card-total-sub">
              <Index.Typography className="left-name">
                Win/Loss
              </Index.Typography>
              <Index.Typography className="right-data">
                {winloseratio?.totalWins && winloseratio?.totalLosses
                  ? `${winloseratio?.totalWins}/${winloseratio?.totalLosses}`
                  : 0}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="card-total card-total-sub">
              <Index.Typography className="left-name">
                Win Ratio
              </Index.Typography>
              <Index.Typography className="right-data">
                {winloseratio?.totalGamesPlayed && winloseratio?.totalWins
                  ? (
                      (winloseratio?.totalWins /
                        winloseratio?.totalGamesPlayed) *
                      100
                    ).toFixed(2)
                  : 0}
                %
              </Index.Typography>
            </Index.Box>
            <Index.Box className="card-total card-total-sub">
              <Index.Typography className="left-name">
                Loss Ratio
              </Index.Typography>
              <Index.Typography className="right-data">
                {winloseratio?.totalGamesPlayed && winloseratio?.totalLosses
                  ? (
                      (winloseratio?.totalLosses /
                        winloseratio?.totalGamesPlayed) *
                      100
                    ).toFixed(2)
                  : 0}
                %
              </Index.Typography>
            </Index.Box>
          </Index.Box>

          <Index.Box className="card-main-head">
            Today's Win-lose Ratio
          </Index.Box>
          <Index.Box className="card-main-body">
            <Index.Box className="card-total">
              <Index.Typography className="left-name">
                Total Games
              </Index.Typography>
              <Index.Typography className="right-data">
                {winloseratio?.gamesPlayedToday || 0}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="card-total card-total-sub">
              <Index.Typography className="left-name">
                Win/Loss
              </Index.Typography>
              <Index.Typography className="right-data">
                {winloseratio?.gamesWinToday && winloseratio?.gamesLossToday
                  ? `${winloseratio?.gamesWinToday}/${winloseratio?.gamesLossToday}`
                  : 0}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="card-total card-total-sub">
              <Index.Typography className="left-name">
                Win Ratio
              </Index.Typography>
              <Index.Typography className="right-data">
                {winloseratio?.gamesPlayedToday && winloseratio?.gamesWinToday
                  ? (
                      (winloseratio?.gamesWinToday /
                        winloseratio?.gamesPlayedToday) *
                      100
                    ).toFixed(2)
                  : 0}
                %
              </Index.Typography>
            </Index.Box>
            <Index.Box className="card-total card-total-sub ">
              <Index.Typography className="left-name">
                Loss Ratio
              </Index.Typography>
              <Index.Typography className="right-data">
                {winloseratio?.gamesPlayedToday && winloseratio?.gamesLossToday
                  ? (
                      (winloseratio?.gamesLossToday /
                        winloseratio?.gamesPlayedToday) *
                      100
                    ).toFixed(2)
                  : 0}
                %
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      {/* Bot Statistics Section */}
      <Index.Box className="bot-stats">
        <Index.Box className="win-lose-main">
          <Index.Box className="card-main-head">
            Bot Statistics Till Date
          </Index.Box>
          <Index.Box className="card-main-body">
            <Index.Box className="card-total">
              <Index.Typography className="left-name">
                Total Bot Games
              </Index.Typography>
              <Index.Typography className="right-data">
                {winloseratio?.botTotalPlay || 0}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="card-total card-total-sub">
              <Index.Typography className="left-name">
                Win/Loss
              </Index.Typography>
              <Index.Typography className="right-data">
                {/* {winloseratio?.botWinTotal || 0}/
                {winloseratio?.botLossTotal || 0} */}
                {/* change request */}
                {winloseratio?.botLossTotal || 0}/
                {winloseratio?.botWinTotal || 0}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="card-total card-total-sub">
              <Index.Typography className="left-name">
                Win Ratio
              </Index.Typography>
              <Index.Typography className="right-data">
                {/* {winloseratio?.botTotalPlay
                  ? (
                      (winloseratio?.botWinTotal / winloseratio?.botTotalPlay) *
                      100
                    ).toFixed(2)
                  : 0}
                % */}
                {/* change request  */}
                {winloseratio?.botTotalPlay
                  ? (
                      (winloseratio?.botLossTotal /
                        winloseratio?.botTotalPlay) *
                      100
                    ).toFixed(2)
                  : 0}{" "}
                %
              </Index.Typography>
            </Index.Box>
            <Index.Box className="card-total card-total-sub">
              <Index.Typography className="left-name">
                Loss Ratio
              </Index.Typography>
              <Index.Typography className="right-data">
                {/* {winloseratio?.botTotalPlay
                  ? (
                      (winloseratio?.botLossTotal /
                        winloseratio?.botTotalPlay) *
                      100
                    ).toFixed(2)
                  : 0}
                % */}
                {/* change request */}
                {winloseratio?.botTotalPlay
                  ? (
                      (winloseratio?.botWinTotal / winloseratio?.botTotalPlay) *
                      100
                    ).toFixed(2)
                  : 0}{" "}
                %
              </Index.Typography>
            </Index.Box>
          </Index.Box>

          <Index.Box className="card-main-head">Bot Statistics Today</Index.Box>
          <Index.Box className="card-main-body">
            <Index.Box className="card-total">
              <Index.Typography className="left-name">
                Total Games
              </Index.Typography>
              <Index.Typography className="right-data">
                {winloseratio?.totalPlayBotToday || 0}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="card-total card-total-sub">
              <Index.Typography className="left-name">
                Win/Loss
              </Index.Typography>
              <Index.Typography className="right-data">
                {/* {winloseratio?.botWinToday || 0}/
                {winloseratio?.botLossToday || 0} */}
                {/* change request */}
                {winloseratio?.botLossToday || 0}/
                {winloseratio?.botWinToday || 0}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="card-total card-total-sub">
              <Index.Typography className="left-name">
                Win Ratio
              </Index.Typography>
              <Index.Typography className="right-data">
                {/* {winloseratio?.botWinToday
                  ? (
                      (winloseratio?.botWinToday /
                        (winloseratio?.botWinToday +
                          winloseratio?.botLossToday)) *
                      100
                    ).toFixed(2)
                  : 0}
                % */}
                {/* change request */}
                {winloseratio?.botLossToday
                  ? (
                      (winloseratio?.botLossToday /
                        (winloseratio?.botWinToday +
                          winloseratio?.botLossToday)) *
                      100
                    ).toFixed(2)
                  : 0}
                %
              </Index.Typography>
            </Index.Box>
            <Index.Box className="card-total card-total-sub">
              <Index.Typography className="left-name">
                Loss Ratio
              </Index.Typography>
              <Index.Typography className="right-data">
                {/* {winloseratio?.botLossToday
                  ? (
                      (winloseratio?.botLossToday /
                        (winloseratio?.botWinToday +
                          winloseratio?.botLossToday)) *
                      100
                    ).toFixed(2)
                  : 0}
                % */}
                {/* change request */}
                {winloseratio?.botWinToday
                  ? (
                      (winloseratio?.botWinToday /
                        (winloseratio?.botWinToday +
                          winloseratio?.botLossToday)) *
                      100
                    ).toFixed(2)
                  : 0}
                %
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default WinLoseratio;
