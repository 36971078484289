export default {
    ADMIN_ERROR_HANDLER: 'ADMIN_ERROR_HANDLER',
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
    SIGNUP : "SIGNUP",
    ALL_USER : "ALL_USER",
    ACTIVE_DEACTIVE_USER : "ACTIVE_DEACTIVE_USER",
    ALL_TRANSACTION : "ALL_TRANSACTION",
    CREATE_UPDATE_GAME : 'CREATE_UPDATE_GAME',
    ALL_GAME_LIST : 'ALL_GAME_LIST',
    ADD_EDIT_GAME_BET : 'ADD_EDIT_GAME_BET',
    GAME_WISE_BET_LIST :  "GAME_WISE_BET_LIST",
    ADD_EDIT_PRIVACY_POLICY : "ADD_EDIT_PRIVACY_POLICY", 
    ADD_EDIT_TERMS_AND_CONDITION : "ADD_EDIT_TERMS_AND_CONDITION", 
    GET_CMS_DETAILS :  "GET_CMS_DETAILS",
    ADD_UPDATE_GAME_RULES: "ADD_UPDATE_GAME_RULES",
    GET_GAME_RULES : "GET_GAME_RULES",
    DELETE_GAME_RULES : "DELETE_GAME_RULES", 
    ROOM_LIST : "ROOM_LIST",
    CHAT_API : 'CHAT_API',
    WALLET_LIST : 'WALLET_LIST',
    USER_WISE_BET_LIST : "USER_WISE_BET_LIST",
    DELETE_GAME_WISE_BET : "DELETE_GAME_WISE_BET",
    DELETE_GAME:"DELETE_GAME",
    CALL_REQUEST_LIST: "CALL_REQUEST_LIST",
    DAILY_BONUS:"DAILY_BONUS",
    BALANCE_REPORT : 'BALANCE_REPORT',
    EDIT_PROFILE : 'EDIT_PROFILE'
    
    
  };
  