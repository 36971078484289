import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import { Api } from "../../config/Api";
import DataService from "../../config/DataService";
import Index from "../Index";
import ErrorShow from "../../component/common/ErrorShow";
import { useLocation } from "react-router-dom";

const ResetPassword = () => {
  const location = useLocation();
  const navigate = Index.useNavigate();

  // All States:
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // All Methods
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({});

  const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,10}$/;

  const submit = async (data) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", location.state.id);
    urlencoded.append("password", data.confirmPassword);

    await DataService.post(Api.User.resetPassword, urlencoded).then((res) => {
      if (res.data.status == 201) {
        toast.success(res.data.message);
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    });
  };

  return (
    <div>
      <Index.Box className="admin-login-main-flex">
        <Index.Box className="admin-login-left-main set-login-box-back">
          <Index.Box className="login-left-bg">
            <img src={Index.Png.ss_logo} className=""></img>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-login-right-main">
          <Index.Box className="admin-login-box">
            <Index.Box className="admin-login-main">
              <form className="form-content" onSubmit={handleSubmit(submit)}>
                <Index.Box className="admin-login-inner set-login-box">
                  <Index.Typography
                    component="h2"
                    variant="h2"
                    className="admin-wel-text"
                  >
                    Reset Password
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="admin-sign-para common-para"
                  >
                    Please enter your New Password!
                  </Index.Typography>

                  <Index.Box className="input-box">
                    <Index.FormHelperText className="form-lable">
                      New Password
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.OutlinedInput
                        className="form-control-eye"
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        name="newPassword"
                        {...register("newPassword", {
                          required: "New Password is required",

                          pattern: {
                            value: PWD_REGEX,
                            message:
                              "Password should be 8-10 characters and include at least 1 letter, 1 number and 1 special character!",
                          },
                        })}
                        error={!!errors?.newPassword}
                        helperText={
                          errors?.newPassword
                            ? errors.newPassword.message
                            : null
                        }
                        endAdornment={
                          <Index.InputAdornment position="end">
                            <Index.IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {!showPassword ? (
                                <Index.VisibilityOff />
                              ) : (
                                <Index.Visibility />
                              )}
                            </Index.IconButton>
                          </Index.InputAdornment>
                        }
                      />

                      <ErrorShow error={errors?.newPassword?.message} />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box">
                    <Index.FormHelperText className="form-lable">
                      Confirm Password
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.OutlinedInput
                        className="form-control-eye"
                        id="outlined-adornment-password"
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirmPassword"
                        {...register("confirmPassword", {
                          required: "Confirm Password is required",

                          pattern: {
                            value: PWD_REGEX,
                            message:
                              "Password should be 8-10 characters and include at least 1 letter, 1 number and 1 special character!",
                          },
                          validate: (val) => {
                            if (watch("newPassword") != val) {
                              return "Your password does not matched";
                            }
                          },
                        })}
                        error={!!errors?.confirmPassword}
                        helperText={
                          errors?.confirmPassword
                            ? errors.confirmPassword.message
                            : null
                        }
                        endAdornment={
                          <Index.InputAdornment position="end">
                            <Index.IconButton
                              aria-label="toggle password visibility"
                              onClick={handleShowConfirmPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {!showConfirmPassword ? (
                                <Index.VisibilityOff />
                              ) : (
                                <Index.Visibility />
                              )}
                            </Index.IconButton>
                          </Index.InputAdornment>
                        }
                      />

                      <ErrorShow error={errors?.confirmPassword?.message} />
                    </Index.Box>
                  </Index.Box>
                  {/* <Index.Box className="flex-all forgot-row">
                                    <Index.Box className='checkbox-main'>
                                        <Index.FormControlLabel control={<Index.Checkbox defaultChecked />} label="Remember Me" className='checkbox-lable' />
                                    </Index.Box>
                                    <Index.Link className='text-decoration-none'>
                                        <Index.Typography component="p" variant='p' className='admin-forgot-para common-para'>Forgot Password?</Index.Typography>
                                    </Index.Link>
                                </Index.Box> */}
                  <Index.Box className="btn-main-primary login-btn-main">
                    <Index.Button
                      className="btn-primary login-btn"
                      type="submit"
                    >
                      Reset Password
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </form>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <ToastContainer />
      </Index.Box>
    </div>
  );
};

export default ResetPassword;
