import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { getAllGameList, deleteGame } from "../../../../redux/auth/action";
import Index from "../../../Index";
import { TablePagination } from "@mui/material";
import Swal from "sweetalert2";
import { ToastContainer } from "react-toastify";

const GameListPage = () => {
  // add user modal

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const AllGameList = useSelector((state) => {
    return state.adminReducer.AllGames;
  });

  const token = useSelector((state) => {
    return state.adminReducer.Token;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const getAllGameDetail = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("gameId", "");
    dispatch(getAllGameList(urlencoded, navigate));
  };

  const deletehandler = async (id) => {
    Swal.fire({
      title: "",
      text: "Are you sure you want to delete Game ?",
      icon: "error",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const urlencoded = new URLSearchParams();
        urlencoded.append("id", id);
        dispatch(deleteGame(urlencoded, token));
      }
    });
  };

  useEffect(() => {
    getAllGameDetail();
  }, []);

  return (
    <>
      <Index.Box className="dashboard-content">
        <ToastContainer />
        <Index.Box className="user-list-flex">
          <Index.Typography
            className="admin-page-title user-list-page-title"
            component="h2"
            variant="h2"
          >
            Game List
          </Index.Typography>
          <Index.Box className="userlist-btn-flex">
            <Index.Box></Index.Box>

            <Index.Box className="flex-all user-list-inner-flex">
              <Index.Box className="adduser-btn-main btn-main-primary">
                <Index.Button
                  className="adduser-btn btn-primary"
                  onClick={() => navigate("/admin/game-list/add")}
                >
                  <img
                    src={Index.Svg.plus}
                    className="plus-icon"
                    alt="plus icon"
                  />
                  Add Game
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dash-box">
                  <Index.Box className="userlist-table-main page-table-main gamewisebet-table">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Games
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            ></Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              Action
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {AllGameList.length > 0 ? (
                            AllGameList?.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            ).map((row) => (
                              <Index.TableRow
                                key={row._id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                  align="justify"
                                >
                                  {row.gameName}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {/* {row.address} */}
                                </Index.TableCell>

                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  <Index.Box className="userdata-btn-flex align-items">
                                    <Index.Button
                                      onClick={() =>
                                        navigate("/admin/game-list/edit", {
                                          state: { id: row._id },
                                        })
                                      }
                                    >
                                      <img src={Index.Svg.blueedit}></img>
                                    </Index.Button>
                                    <Index.Button
                                      onClick={() => deletehandler(row._id)}
                                    >
                                      <img src={Index.Svg.trash}></img>
                                    </Index.Button>
                                    {/* <Index.Button>
                                      <img src={Index.Svg.yelloweye}></img>
                                    </Index.Button> */}
                                    {/* <Index.Box className="switch-main">
                                    <Index.FormControlLabel
                                      control={
                                        <IOSSwitch
                                          sx={{ m: 1 }}
                                          defaultChecked
                                          className="MuiSwitch-track-active"
                                        />
                                      }
                                      className="switch-lable"
                                    />
                                  </Index.Box> */}
                                  </Index.Box>
                                </Index.TableCell>
                              </Index.TableRow>
                            ))
                          ) : (
                            <Index.TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                No Record Found
                              </Index.TableCell>
                            </Index.TableRow>
                          )}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>{" "}
                <Index.Box className="pagination-main">
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[AllGameList?.length % 10]}
                    count={AllGameList?.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelDisplayedRows={({ from, to, count }) =>
                      `Records ${from}-${to} of ${count}`
                    }
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default GameListPage;
