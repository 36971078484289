import usericon from './images/png/user.png';
import logo from './images/png/logo.png';
import edit from './images/png/edit.png';
import loginbg from './images/png/login-bg.png';
import userlist1 from './images/png/userlist1.png';
import bergurmenu from './images/png/bergur-menu.png';
import close from './images/png/close.png';
import ss_logo from './images/png/SS_logo.png'






const Png = {
      usericon,
      logo,
      edit,
      loginbg,
      userlist1,
      bergurmenu,
      close,
      ss_logo,
}

export default Png;