import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { ToastContainer } from "react-toastify";
import Index from "../../../../component/Index";
import { Api } from "../../../../config/Api";
import DataService from "../../../../config/DataService";
import { addEditGame } from "../../../../redux/auth/action";

const AddGame = () => {
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [editId, setEditId] = useState(location?.state?.id);

  const [url, setUrl] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [Banner, setBanner] = useState("");
  const [thumbnail, setThumbnail] = useState("");

  const [BannerError, setBannerError] = useState("");
  const [thumbnailError, setThumbnailError] = useState("");
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // const work = watch((data) => {
  //   console.log(data, 8887);
  //   if (data) {
  //     let urllink = URL.createObjectURL(data.image[0]);
  //     setUrl(urllink);
  //   }
  // });

  // const work2 = watch((data) => {
  //   console.log(data, 8887);
  //   if (data) {
  //     let urllink = URL.createObjectURL(data.thumbnail[0]);
  //     setThumbnailUrl(urllink);
  //   }
  // });.

  const ThumbnailHandler = (data) => {
    console.log(data, 8887);
    if (data) {
      let reader = new FileReader();
      reader?.readAsDataURL(data[0]);
      reader.onload = (e) => {
        var image2 = new Image();
        let flag = 1;
        image2.src = e.target.result;
        image2.onload = () => {
          var height = image2.height;
          var width = image2.width;
          console.log(height, width, "Image");
          if (width >= 180 && height >= 180) {
            let urllink = URL.createObjectURL(data[0]);
            setThumbnailUrl(urllink);
            setThumbnailError("");
          } else {
            setThumbnailError("Please select Thumbnail (180*180px)");
          }
        };
      };
    }
  };

  const BannerHandler = (data) => {
    console.log(data, 1234);
    if (data) {
      let reader = new FileReader();
      reader.readAsDataURL(data[0]);
      console.log(reader, "reader");
      reader.onload = (e) => {
        console.log(e, 69);
        var image2 = new Image();
        let flag = 1;
        image2.src = e.target.result;
        image2.onload = () => {
          var height = image2.height;
          var width = image2.width;
          console.log(height, width, "Image");
          if (width >= 1003 && height >= 480) {
            let urllink = URL.createObjectURL(data[0]);
            setUrl(urllink);
            setBannerError("");
          } else {
            setBannerError("Please select Banner (1003*480px)");
          }
          //   this.setState({
          //     imagError2: 'Please select image(1500*400px)',
          //   });
          //   imageError2 = 'Please select image(1500*400px)';
          //   fileName2 = '';
          //   this.setState({
          //     bannerImage2: '',
          //     fileName2: '',
          //   });
          // } else if (width < 1500 && height < 400) {
          //   this.setState({
          //     imagError2: 'Please select image(1500*400px)',
          //   });
          //   imageError2 = 'Please select image(1500*400px)';
          //   fileName2 = '';
          //   this.setState({
          //     bannerImage2: '',
          //     fileName2: '',
          //   });
          // } else {
          //   this.setState({
          //     bannerImage2: e.target.result,
          //     fileName2: fileName2,
          //   });
          // }
        };
      };
    }
  };

  const GetSingleGameData = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", location?.state?.id);
    await DataService.post(Api.User.getSingleGame, urlencoded).then((res) => {
      reset({ gameName: `${res.data.data.gameName}` });
      console.log(
        `${process.env.REACT_APP_BASE_URL}/Images/${res.data.data.thumbnail}`,
        744
      );
      setThumbnailUrl(
        `${process.env.REACT_APP_BASE_URL}/Images/${res.data.data.thumbnail}`
      );
      setUrl(
        `${process.env.REACT_APP_BASE_URL}/Images/${res.data.data.banner}`
      );
      setBanner(
        `${process.env.REACT_APP_BASE_URL}/Images/${res.data.data.banner}`
      );
      setThumbnail(
        `${process.env.REACT_APP_BASE_URL}/Images/${res.data.data.thumbnail}`
      );
    });
  };

  console.log(url, thumbnailUrl, 811);

  useEffect(() => {
    console.log(editId, 28);

    if (editId) {
      GetSingleGameData();
    }
  }, [editId]);

  const submit = (data) => {
    if (Banner == "") {
      setBannerError("Banner is Required");
    } else {
      setBannerError("");
    }

    if (thumbnail == "") {
      setThumbnailError("Thumbnail is Required");
    } else {
      setThumbnailError("");
    }

    if (Banner && thumbnail) {
      const urlencoded = new FormData();
      if (location?.state?.id) {
        urlencoded.append("id", location?.state?.id);
      }
      urlencoded.append("gameName", data.gameName);
      urlencoded.append("thumbnail", thumbnail);
      urlencoded.append("banner", Banner);
      dispatch(addEditGame(urlencoded, navigate));
    }

    console.log(BannerError, Banner, 44, thumbnail, thumbnailError);
  };

  return (
    <div>
      <Index.Box className="add-user-modal-inner-main modal-inner">
        <Index.Box className="modal-header">
          <Index.Typography
            id="modal-modal-title"
            className="modal-title"
            variant="h6"
            component="h2"
          >
            {params.slug.charAt(0).toUpperCase() + params.slug.slice(1)} Game
          </Index.Typography>
        </Index.Box>
        <form className="form-content" onSubmit={handleSubmit(submit)}>
          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Game Name
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="gameName"
                {...register("gameName", {
                  required: "Game Name  is required.",
                })}
                error={Boolean(errors.gameName)}
                helperText={errors?.gameName?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="edit-profile-flex res-edit-profile-flex">
            <Index.Box className="upload-section">
              <Index.Box className="file-upload-btn-main banner-img">
                <img
                  // src={Index.Png.usericon}
                  src={
                    url
                      ? url
                      : "https://climate.onep.go.th/wp-content/uploads/2020/01/default-image.jpg"
                  }
                  className="upload-profile-img"
                  alt="upload img"
                ></img>
                <Index.Box className="upload-btn ">
                  {/* <Index.Button
                    variant="contained"
                    component="label"
                    // className="file-upload-btn"
                  >
                    Upload Banner
                    <input
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                      {...register("image", {
                        required: "Image is required",
                      })}
                    />
                  </Index.Button> */}
                  <Index.Button
                    variant="contained"
                    component="label"

                    // className="file-upload-btn"
                  >
                    Upload Banner
                    <input
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                      onChange={(e) => {
                        setBanner(e.target.files[0]);
                        BannerHandler(e.target.files);
                      }}
                    />
                  </Index.Button>
                </Index.Box>
              </Index.Box>
              <Index.Box className="file-upload-btn-main">
                <Index.FormHelperText
                  sx={{
                    textAlign: "center",
                    marginBottom: 4,
                    color: "#d32f2f",
                  }}
                >
                  <p>{BannerError}</p>
                </Index.FormHelperText>
              </Index.Box>
            </Index.Box>
            <Index.Box className="upload-section">
              <Index.Box className="file-upload-btn-main">
                <img
                  // src={Index.Png.usericon}
                  src={
                    thumbnailUrl
                      ? thumbnailUrl
                      : "https://climate.onep.go.th/wp-content/uploads/2020/01/default-image.jpg"
                  }
                  className="upload-profile-img"
                  alt="upload img"
                ></img>
                <Index.Box className="upload-btn">
                  <Index.Button
                    variant="contained"
                    component="label"
                    // className="file-upload-btn"
                  >
                    Upload Thumbnail
                    <input
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                      onChange={(e) => {
                        setThumbnail(e.target.files[0]);
                        ThumbnailHandler(e.target.files);
                      }}
                    />
                  </Index.Button>
                </Index.Box>
              </Index.Box>
              <Index.Box className="file-upload-btn-main">
                <Index.FormHelperText
                  sx={{
                    textAlign: "center",
                    marginBottom: 4,
                    color: "#d32f2f",
                  }}
                >
                  <p>{thumbnailError}</p>
                </Index.FormHelperText>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          {/* <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Winning Price
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                {...register("winningPrice", {
                  required: "Winning Price  is required.",
                })}
                error={Boolean(errors.winningPrice)}
                helperText={errors?.winningPrice?.message}
              />
            </Index.Box>
          </Index.Box> */}

          <Index.Box className="modal-user-btn-flex">
            <Index.Box className="discard-btn-main border-btn-main">
              <Index.Button
                className="discard-user-btn border-btn"
                onClick={() => navigate("/admin/game-list")}
              >
                Cancel
              </Index.Button>
            </Index.Box>
            <Index.Box className="save-btn-main border-btn-main">
              <Index.Button className="save-user-btn border-btn" type="submit">
                <img src={Index.Svg.save} className="user-save-icon"></img>Save
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </form>
        <ToastContainer />
      </Index.Box>
    </div>
  );
};

export default AddGame;
