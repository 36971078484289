import Index from "../Index";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/auth/action";
import { useDispatch, useSelector } from "react-redux";

export default function Header(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const admin = useSelector((state) => {
    return state.adminReducer.login;
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openSidebar = (event) => {
    if (props.open) {
      document.body.classList.add("body-overflow");
    } else {
      document.body.classList.remove("body-overflow");
    }
  };

  useEffect(() => {
    openSidebar();
  }, [props?.open]);

  const Adminlogout = () => {
    dispatch(logout(navigate));
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <>
      <Index.Box
        className={`admin-header-main ${
          props.open ? "active" : "admin-header-deactive"
        }`}
      >
        <Index.Box className="admin-header-left">
          <Index.Box className="admin-header-logo-main">
            {/* <img
              src={Index.Png.ss_logo}
              className="admin-sidebar-logo"
              alt="logo"
            /> */}
            <Index.Button
              className="admin-bergur-button"
              onClick={() => {
                props.setOpen(!props.open);
                openSidebar();
              }}
            >
              <img
                src={Index.Svg.bergurmenu}
                className="bergurmenu-icon"
                alt="dashboard logo"
              ></img>
            </Index.Button>
          </Index.Box>
          <Index.Box className="admin-header-right">
            {/* <Index.Box className="admin-header-icon-box">
              <img
                src={Index.Svg.notification}
                className="admin-header-icon"
                alt="dashboard logo"
              ></img>
            </Index.Box>
            <Index.Box className="admin-header-icon-box">
              <img
                src={Index.Svg.settings}
                className="admin-header-icon"
                alt="dashboard bell icon"
              ></img>
            </Index.Box> */}
            <Index.Box className="admin-header-drop-main">
              <Index.Button
                className="drop-header-btn"
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <Index.Box className="flex-drop-main">
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/Images/${admin?.profile}`}
                    className="admin-header-profile-icon"
                    alt="dashboard bell icon"
                  ></img>
                  <Index.Box className="title-admin-drop">
                    <Index.Typography
                      variant="h5"
                      component="h5"
                      className="admin-header-drop"
                    >
                      Admin
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Button>
            </Index.Box>
            <Index.Menu
              className="drop-header-menu"
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <Index.MenuItem
                onClick={() => {
                  handleClose();
                  navigate("/admin/profile");
                }}
                className="drop-header-menuitem"
              >
                <img src={Index.Svg.profilegrey} className="drop-header" />
                Profile
              </Index.MenuItem>

              {/* <Index.MenuItem
                onClick={handleClose}
                className="drop-header-menuitem"
              >
                <img src={Index.Svg.settings} className="drop-header" /> Account
                Settings
              </Index.MenuItem> */}
              <Index.MenuItem
                onClick={() => {
                  handleClose();
                  Adminlogout();
                }}
                className="drop-header-menuitem"
              >
                {" "}
                <img src={Index.Svg.edit} className="drop-header" /> Sign Out
              </Index.MenuItem>
            </Index.Menu>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
