import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Index from "../../../Index";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { editProfile } from "../../../../redux/auth/action";
import { ToastContainer } from "react-toastify";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function EditProfile() {
  const [profile, setProfile] = useState("");
  const [url, setUrl] = useState("");

  const [profileError, setProfileError] = useState("");
  const dispatch = useDispatch();

  const [Flag, setFlag] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({});

  const token = useSelector((state) => {
    return state.adminReducer.Token;
  });
  const admin = useSelector((state) => {
    return state.adminReducer.login;
  });

  useEffect(() => {
    setValue("Name", admin?.name);
    setValue("email", admin?.email);
    setUrl(`${process.env.REACT_APP_BASE_URL}/Images/${admin?.profile}`);
  }, [Flag]);

  const imageHandler = (value) => {
    setProfile(value[0]);

    let urllink = URL.createObjectURL(value[0]);
    setUrl(urllink);
    setProfileError("");
  };

  const submit = (data) => {
    if (profile == "") {
      setProfileError("select Profile");
    } else {
      setProfileError("");
    }
    console.log(data, 78, profile);
    const formData = new FormData();
    formData.append("name", data?.Name);
    formData.append("email", data?.email);
    formData.append("profile", profile);
    dispatch(editProfile(formData, token));
  };

  return (
    <Index.Box className="dashboard-content add-user-containt">
      <ToastContainer />
      <Index.Typography
        className="admin-page-title"
        component="h2"
        variant="h2"
      >
        Profile
      </Index.Typography>

      <Index.Box className="tabs-main-box">
        <Index.Box sx={{ width: "100%" }}>
          <TabPanel value={0} index={0} className="admin-tabpanel">
            <Index.Box className="tabpanel-main">
              <Index.Box className="page-border">
                <Index.Typography
                  className="common-para edit-highlight-text"
                  component="p"
                  variant="p"
                >
                  General
                </Index.Typography>
                <Index.Typography
                  className="common-para edit-para-text"
                  component="p"
                  variant="p"
                >
                  Basic info, like your name and email that will displayed in
                  public
                </Index.Typography>
                <Index.Box className="edit-profile-flex">
                  <Index.Box className="file-upload-btn-main">
                    <img
                      src={url ? url : Index.Png.usericon}
                      className="upload-profile-img"
                      alt="upload img"
                    ></img>
                    <Index.Button
                      variant="contained"
                      component="label"
                      className="file-upload-btn"
                    >
                      <img
                        src={Index.Svg.edit}
                        className="upload-icon-img"
                        alt="upload img"
                      ></img>
                      <input
                        hidden
                        accept="image/*"
                        multiple
                        type="file"
                        onChange={(e) => imageHandler(e.target.files)}
                      />
                    </Index.Button>
                  </Index.Box>
                  {/* <Index.Box className="switch-main">
                    <Index.FormControlLabel
                      control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                      label="Public Profile"
                      className="switch-lable"
                    />
                  </Index.Box> */}
                </Index.Box>
                <Index.Box className="add-user-data-main">
                  <form
                    className="form-content"
                    onSubmit={handleSubmit(submit)}
                  >
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box
                        display="grid"
                        className="display-row"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                              Name
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                name="Name"
                                {...register("Name", {
                                  required: "Name  is Required.",
                                })}
                                error={Boolean(errors.Name)}
                                helperText={errors?.Name?.message}
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                              Email
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                name="email"
                                {...register("email", {
                                  required: "Email  is Required.",
                                })}
                                error={Boolean(errors.email)}
                                helperText={errors?.email?.message}
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="user-btn-flex">
                            <Index.Box className="discard-btn-main border-btn-main">
                              <Index.Button
                                className="discard-user-btn border-btn"
                                onClick={() => setFlag(!Flag)}
                              >
                                Discard
                              </Index.Button>
                            </Index.Box>
                            <Index.Box className="save-btn-main border-btn-main">
                              <Index.Button
                                className="save-user-btn border-btn"
                                type="submit"
                              >
                                <img
                                  src={Index.Svg.save}
                                  className="user-save-icon"
                                ></img>
                                Save
                              </Index.Button>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </form>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </TabPanel>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
