import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// Redux Thunk
import { Provider } from "react-redux";
import Store from './redux/Store';
import { PersistGate } from "redux-persist/integration/react";

const persistStore = Store();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={persistStore.store}>
      <PersistGate persistor={persistStore.persistor}>
        <App />
      </PersistGate>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
