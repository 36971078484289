import React, { useEffect, useState } from "react";
import Index from "../../../../../component/Index";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { addUpdateGameRules } from "../../../../../redux/auth/action";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
import ErrorShow from "../../../../../component/common/ErrorShow";

export default function AddGameRules() {
  const params = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({});

  const token = useSelector((state) => {
    return state.adminReducer.Token;
  });

  const AllGameList = useSelector((state) => {
    return state.adminReducer.AllGames;
  });

  const [description, setDiscription] = useState("");
  const [descriptionError, setDiscriptionError] = useState("");
  const [editId, setEditId] = useState(params?.id);
  const [select, setSelect] = useState("");

  const GetSingleGameRule = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("gameId", location?.state?.gameId);
    await DataService.post(Api.User.getSingleGameRules, urlencoded).then(
      (res) => {
        setDiscription(res?.data?.data[0]?.description);
        setSelect(res?.data?.data[0]?.gameId?._id);
        setValue("gameName", res?.data?.data[0]?.gameId?._id);
      }
    );
  };

  useEffect(() => {
    if (editId) {
      GetSingleGameRule();
    }
  }, [editId, select]);

  const submit = (data) => {
    if (description == "") {
      setDiscriptionError("Please Enter Description");
    } else {
      setDiscriptionError("");
    }

    if (description !== "") {
      const urlencoded = new URLSearchParams();
      urlencoded.append("description", description);
      urlencoded.append("gameId", data.gameName);
      if (params?.id) {
        urlencoded.append("id", params?.id);
      }
      dispatch(addUpdateGameRules(urlencoded, token, navigate));
    }
  };

  ClassicEditor.defaultConfig = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "|",
        "bulletedList",
        "numberedList",
        "|",
        "insertTable",
        "|",
        "imageUpload",
        "|",
        "undo",
        "redo",
      ],
    },
    image: {
      toolbar: [
        "imageStyle:full",
        "imageStyle:side",
        "|",
        "imageTextAlternative",
      ],
    },
    table: {
      contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
    language: "en",
  };

  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Box className="user-list-flex">
          <Index.Typography
            className="admin-page-title user-list-page-title"
            component="h2"
            variant="h2"
          >
            {params.slug.charAt(0).toUpperCase() + params.slug.slice(1)} Game
            Rules
          </Index.Typography>
        </Index.Box>
        <Index.Box>
          <form className="form-content" onSubmit={handleSubmit(submit)}>
            <Index.Box className="dropdown-box input-box modal-input-box">
              <Index.FormHelperText className="form-lable">
                Select Game
              </Index.FormHelperText>

              <Index.FormControl className="form-control">
                <Controller
                  name="gameName"
                  control={control}
                  {...register("gameName", {
                    required: "Please select Game",
                  })}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Index.Select
                        sx={{
                          "& .MuiFormHelperText-root": {
                            ml: 0,
                            mt: "5px",
                          },
                        }}
                        // defaultValue={select}
                        labelId="gameName"
                        error={Boolean(errors.gameName)}
                        className="dropdown-select"
                        id="gameName"
                        value={value !== undefined ? value : ""}
                        onChange={onChange}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {AllGameList?.map((data) => (
                          <Index.MenuItem value={data?._id}>
                            {data?.gameName}
                          </Index.MenuItem>
                        ))}
                      </Index.Select>
                    );
                  }}
                />
                {/* <span><img src={Svg.greydown} className="grey-down pay-down" alt='grey down arrow'></img></span> */}
              </Index.FormControl>
              
              <ErrorShow error={errors.gameName?.message}/>
            </Index.Box>

            <Index.Box className="input-box modal-input-box">
              <Index.Box className="set-ck-editor">
                <CKEditor
                  editor={ClassicEditor}
                  data={description}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setDiscription(data);
                  }}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </Index.Box>
              <ErrorShow  error={descriptionError}/>
            </Index.Box>
            <Index.Box className="save-btn-main border-btn-main">
              <Index.Button className="save-user-btn border-btn" type="submit">
                <img src={Index.Svg.save} className="user-save-icon"></img>Save
              </Index.Button>
            </Index.Box>
          </form>
        </Index.Box>
      </Index.Box>
    </>
  );
}
