import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import Parser from "html-react-parser";
import {
  deleteGameRules,
  getGameRules,
} from "../../../../../redux/auth/action";
import Index from "../../../../Index";
import { TablePagination } from "@mui/material";
import Swal from "sweetalert2";
import NoRecordFound from "../../../../../component/common/NoRecordFound";

const GameWiseRules = () => {
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const token = Index.useSelector((state) => {
    return state.adminReducer.Token;
  });

  const AllGameRules = Index.useSelector((state) => {
    return state.adminReducer.gameRules;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
  };

  const getAllGameDetail = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("gameId", "");
    dispatch(getGameRules(urlencoded));
  };

  useEffect(() => {
    getAllGameDetail();
  }, []);

  const deletehandler = async (id) => {
    Swal.fire({
      title: "",
      text: "Are you sure you want to delete this Game Rule ?",
      icon: "error",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const urlencoded = new URLSearchParams();
        urlencoded.append("id", id);
        dispatch(deleteGameRules(urlencoded, token));
      }
    });
  };

  return (
    <>
      <Index.Box className="dashboard-content">
        <ToastContainer />
        <Index.Box className="user-list-flex">
          <Index.Typography
            className="admin-page-title user-list-page-title"
            component="h2"
            variant="h2"
          >
            Game Wise Rules
          </Index.Typography>
          <Index.Box className="userlist-btn-flex">
            <Index.Box></Index.Box>

            <Index.Box className="flex-all user-list-inner-flex">
              <Index.Box className="adduser-btn-main btn-main-primary">
                <Index.Button
                  className="adduser-btn btn-primary"
                  onClick={() => navigate("/admin/game-rules/add")}
                >
                  <img
                    src={Index.Svg.plus}
                    className="plus-icon"
                    alt="plus icon"
                  />
                  Add Rules
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dash-box">
                  <Index.Box className="userlist-table-main  page-table-main gamewisebet-table">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Games
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              Game Rules
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              Action
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {AllGameRules.length > 0 ? (
                            AllGameRules?.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            ).map((row) => (
                              <Index.TableRow
                                key={row._id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                  align="justify"
                                >
                                  {row?.gameId ? row?.gameId?.gameName : "-"}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td gamerules-description"
                                >
                                  {Parser(row.description)}
                                </Index.TableCell>

                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  <Index.Box className="userdata-btn-flex">
                                    <Index.Button
                                      onClick={() =>
                                        navigate(
                                          `/admin/game-rules/edit/${row._id}`,
                                          {
                                            state: { gameId: row?.gameId?._id },
                                          }
                                        )
                                      }
                                    >
                                      <img src={Index.Svg.blueedit}></img>
                                    </Index.Button>
                                    <Index.Button
                                      onClick={() => deletehandler(row._id)}
                                    >
                                      <img src={Index.Svg.trash}></img>
                                    </Index.Button>
                                    <Index.Button>
                                      <img src={Index.Svg.yelloweye}></img>
                                    </Index.Button>
                                    {/* <Index.Box className="switch-main">
                                    <Index.FormControlLabel
                                      control={
                                        <IOSSwitch
                                          sx={{ m: 1 }}
                                          defaultChecked
                                          className="MuiSwitch-track-active"
                                        />
                                      }
                                      className="switch-lable"
                                    />
                                  </Index.Box> */}
                                  </Index.Box>
                                </Index.TableCell>
                              </Index.TableRow>
                            ))
                          ) : (
                            <NoRecordFound />
                          )}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>{" "}
                <Index.Box className="pagination-main">
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[AllGameRules?.length % 10]}
                    count={AllGameRules?.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default GameWiseRules;
