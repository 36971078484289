import React from "react";
import Index from "../Index";

const NoRecordFound = () => {
  return (
    <Index.TableRow>
      <Index.TableCell
        component="td"
        variant="td"
        scope="row"
        className="table-td"
      >
        No Record found
      </Index.TableCell>
      <Index.TableCell></Index.TableCell>
      <Index.TableCell></Index.TableCell>
    </Index.TableRow>
  );
};

export default NoRecordFound;
