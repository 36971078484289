import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;

var token = localStorage.getItem('token');

const header = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    auth: token,
  },
};

const DataService = axios.create({
  baseURL: BASE_URL,
  headers: header.headers,
});

export default DataService;
