import { SwipeableDrawer, TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callReqList } from "../../../../redux/auth/action";
import Index from "../../../Index";
import moment from "moment/moment";
import { Export_excel } from "../../../../component/common/ExportAll";

export default function RequestList() {
  const dispatch = useDispatch();

  const [userList, setUserList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [exportDetails, setExportDetails] = useState([]);

  const token = useSelector((state) => {
    return state.adminReducer.Token;
  });

  const callRequestList = useSelector((state) => {
    return state.adminReducer.callReqList;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  useEffect(() => {
    setUserList(callRequestList);
    setFilterData(callRequestList);

    const updatedExportDetails = callRequestList.map((item) => {
      let username = item.firstName + " " + item.lastName;
      console.log(
        moment(item?.createdAt).format("DD-MM-YYYY , hh:mm:ss a"),
        "item"
      );
      let createdDate = moment(item?.createdAt).format("DD-MM-YYYY, hh:mm:ss");
      return {
        Date_Time: createdDate,
        UserId: item?.userId,
        Name: username,
        Email: item?.email,
        MobileNo: item.mobileNumber,
      };
    });

    setExportDetails(updatedExportDetails);
  }, [callRequestList]);

  const handleSearch = (value) => {
    const result = userList.filter((item) => {
      return (
        item.firstName.toLowerCase().includes(value.toLowerCase()) ||
        item.lastName.toLowerCase().includes(value.toLowerCase()) ||
        item.email.toLowerCase().includes(value.toLowerCase()) ||
        item.mobileNumber.toString().includes(value) ||
        item.userId.toString().includes(value)
      );
    });
    setFilterData(result);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    dispatch(callReqList(token));
  }, []);
  console.log(exportDetails, "exportDetails");
  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Box className="user-list-flex">
          <Index.Typography
            className="admin-page-title user-list-page-title"
            component="h2"
            variant="h2"
          >
            Call Requests List
          </Index.Typography>
          <Index.Box className="userlist-btn-flex call-userlist-btn-flex">
            <Index.Box className="user-search-main">
              <Index.Box className="user-search-box call-req-list-search">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Game call request search"
                    onChange={(e) => handleSearch(e.target.value)}
                  />

                  <img
                    src={Index.Svg.search}
                    className="search-grey-img"
                    alt="search grey img"
                  ></img>

                  <Index.Box className="adduser-btn-main btn-main-primary exp-btn exp-btn2 ">
                    <Index.Button
                      className="adduser-btn btn-primary"
                      onClick={() => {
                        let curDate = moment(new Date()).format("DD-MM-YYYY");
                        Export_excel(
                          exportDetails,
                          `Call Request List (${curDate})`
                        );
                      }}
                    >
                      <img
                        src={Index.Svg.plus}
                        className="plus-icon"
                        alt="plus icon"
                      />
                      Export CSV
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="filter-main">
              {["right"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <SwipeableDrawer
                    className="filter-main"
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    onOpen={toggleDrawer(anchor, true)}
                  >
                    <Index.Box className="filter-header">
                      <Index.Typography className="filter-title">
                        User Filter
                      </Index.Typography>
                      <img
                        src={Index.Png.close}
                        className="filter-close-icon"
                      />
                    </Index.Box>
                    <Index.Box className="filter-inner-main">
                      <Index.Box className="input-box filter-input-box">
                        <Index.FormHelperText className="form-lable">
                          Name
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            placeholder=""
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-box filter-input-box">
                        <Index.FormHelperText className="form-lable">
                          City
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            placeholder=""
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="checkbox-main filter-checkbox-main input-box">
                        <Index.FormHelperText className="form-lable">
                          Status
                        </Index.FormHelperText>
                        <Index.FormControlLabel
                          control={<Index.Checkbox defaultChecked />}
                          label="Active"
                          className="checkbox-lable"
                        />
                        <Index.FormControlLabel
                          control={<Index.Checkbox />}
                          label="Deactivate"
                          className="checkbox-lable"
                        />
                        <Index.FormControlLabel
                          control={<Index.Checkbox />}
                          label="Pending"
                          className="checkbox-lable"
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="filter-footer">
                      <Index.Box className="filter-btn-main border-btn-main btn-main-primary">
                        <Index.Button className="border-btn filter-cancel-btn">
                          Cancel
                        </Index.Button>
                        <Index.Button className="btn-primary filter-btn">
                          Filter
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </SwipeableDrawer>
                </React.Fragment>
              ))}
            </Index.Box>

            {/* <Index.Box className="flex-all user-list-inner-flex">
              <Index.Box className="adduser-btn-main btn-main-primary">
                <Index.Button
                  className="adduser-btn btn-primary"
                  onClick={handleOpen}
                >
                  <img
                    src={Index.Svg.plus}
                    className="plus-icon"
                    alt="plus icon"
                  />
                  Add User
                </Index.Button>
              </Index.Box>
            </Index.Box> */}
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dash-box">
                  <Index.Box className="userlist-table-main page-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Date & Time
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              USER ID
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              NAME
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              EMAIL
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              MOBILE NUMBER
                            </Index.TableCell>
                            {/* <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              REFERRAL CODE
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              STATUS
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              ACTION
                            </Index.TableCell> */}
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {filterData?.length > 0 ? (
                            filterData
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((row) => {
                                let username =
                                  row.firstName + " " + row.lastName;

                                let datetime = moment(row.createdAt).format(
                                  "DD-MM-YYYY , hh:mm:ss a"
                                );
                                return (
                                  <Index.TableRow
                                    key={row.name}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {datetime}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row.userId ? row.userId : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {username}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row.email}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row.mobileNumber}
                                    </Index.TableCell>
                                  </Index.TableRow>
                                );
                              })
                          ) : (
                            <Index.TableRow>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                No Record found
                              </Index.TableCell>
                              <Index.TableCell></Index.TableCell>
                              <Index.TableCell></Index.TableCell>
                              <Index.TableCell></Index.TableCell>
                            </Index.TableRow>
                          )}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>{" "}
                <Index.Box className="pagination-main">
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[callRequestList?.length % 10]}
                    count={callRequestList?.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelDisplayedRows={({ from, to, count }) =>
                      `Records ${from}-${to} of ${count}`
                    }
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
