const USER = "user";
const ADMIN = "admin";
const COMMON = "common";

export const Api = {
  User: {
    login: `${ADMIN}/login`,
    forgotPassword: `${ADMIN}/forgot-password`,
    resetPassword: `${ADMIN}/reset-password`,
    verifyOtp: `${ADMIN}/verify-otp`,
    getSingleGame: `${COMMON}/getgamebyid`,
    getSingleGameRules: `${COMMON}/game-rule-by-id-or-all`,
    dashboard: `${ADMIN}/dashboard-count`,
    WinLoseRatio: `${ADMIN}/win-lose-ratio`,
    EarnCoinList: `${ADMIN}/user-wised-earned-coin`,
    dateWiseList: `${ADMIN}/show-datewise-gameplayed`,
    avgActiveGamePlayer: `${ADMIN}/averageactivegameplayed`,
    totalactive: `${ADMIN}/totalactive-avgplayer`,
    totalRevenue: `${ADMIN}/total-revenue`,
    totalBalanceOfAllUser: `${ADMIN}/alluser-totalbalance`,
  },
};
