// SocketContext.js
import React, { createContext, useContext, useEffect } from 'react';
import { io } from 'socket.io-client';

const SocketContext = createContext();

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
  const URL = process.env.REACT_APP_SOCKET_URL === 'production' ? undefined : 'http://localhost:4000';
  const socket = io(process.env.REACT_APP_SOCKET_URL);


  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};
