import type from "./type";
import { toast } from "react-toastify";

const initialState = {
  islogin: false,
  Token: "",
  login: {},
  allUser: [],
  transactionList: [],
  gameWiseBet: [],
  AllGames: [],
  game: {},
  Bet: {},
  Cms: [],
  gameRules: [],
  RoomList: [],
  chatData: {},
  paymentData: [],
  userBetList: [],
  callReqList: [],
  BalanceReport: [],
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    // Signup
    case type.SIGNUP:
      if (action.payload.status === 201) {
        toast.success("Register successfully");
      } else {
        toast.error(action.payload.message);
      }
      return {
        ...state,
        login: action.payload.data,
      };

    // Logout
    case type.LOGOUT:
      return initialState;

    // Login
    case type.LOGIN:
      if (action.payload.status === 200) {
        toast.success("Logged in successfully");
      } else {
        toast.error(action.payload.message);
      }
      return {
        ...state,
        login: action.payload.data,
        Token: action.payload.data.token,
        islogin: true,
      };

    // Edit Profile
    case type.EDIT_PROFILE:
      if (action.payload.status === 200) {
        toast.success("Profile Update Successfully");
      } else {
        toast.error(action.payload.message);
      }
      return {
        ...state,
        login: action.payload.data,
        islogin: true,
      };

    // get All Transaction
    case type.ALL_TRANSACTION:
      return {
        ...state,
        transactionList: action.payload.data,
      };

    // Get All User
    case type.ALL_USER:
      return {
        ...state,
        allUser: action.payload.data,
      };

    // Active DeActive User
    case type.ACTIVE_DEACTIVE_USER:
      toast.success(action.payload.message);
      return {
        ...state,
        allUser: action.payload.data,
      };

    // Create A New Game
    case type.CREATE_UPDATE_GAME:
      if (action.payload.status === 200 || action.payload.status === 201) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
      return {
        ...state,
        game: action.payload.data,
      };

    // Get All Games
    case type.ALL_GAME_LIST:
      return {
        ...state,
        AllGames: action.payload.data,
      };

    // Add Edit Game Bet
    case type.ADD_EDIT_GAME_BET:
      if (action.payload.status === 201 || action.payload.status === 200) {
        toast.success("Game Wise Bet Added.");
      } else {
        toast.error(action.payload.message);
      }
      return {
        ...state,
        Bet: action.payload.data,
      };

    // Delete Game
    case type.DELETE_GAME:
      if (action.payload.status === 201 || action.payload.status === 200) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
      return {
        ...state,
        AllGames: action.payload.data,
      };

    // Game wise bet List
    case type.GAME_WISE_BET_LIST:
      return {
        ...state,
        gameWiseBet: action.payload.data,
      };

    // Delete Game Wise Bet
    case type.DELETE_GAME_WISE_BET:
      if (action?.payload?.status === 201 || action?.payload?.status === 200) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
      return {
        ...state,
        gameWiseBet: action.payload.data,
      };

    // CMS Details
    case type.GET_CMS_DETAILS:
      return {
        ...state,
        Cms: action.payload.data,
      };

    // Add Edit Privacy Policy
    case type.ADD_EDIT_PRIVACY_POLICY:
      if (action.payload.status === 201) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
      return {
        ...state,
        Cms: action.payload.data,
      };

    // Add Edit Terms & Condition
    case type.ADD_EDIT_TERMS_AND_CONDITION:
      if (action.payload.status === 201) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
      return {
        ...state,
        Cms: action.payload.data,
      };

    // Error Handler
    case type.ADMIN_ERROR_HANDLER:
      toast.error(action.payload.message);
      return {
        ...state,
      };

    // Add Update Game Rules
    case type.ADD_UPDATE_GAME_RULES:
      if (action.payload.status === 201) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
      return {
        ...state,
        gameRules: action.payload.data,
      };

    // Game Rules List
    case type.GET_GAME_RULES:
      return {
        ...state,
        gameRules: action.payload.data,
      };

    // Delete Game Rules
    case type.DELETE_GAME_RULES:
      if (action.payload.status === 201) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
      return {
        ...state,
        gameRules: action.payload.data,
      };

    // Get Room List
    case type.ROOM_LIST:
      return {
        ...state,
        RoomList: action.payload.data,
      };

    // Chat Details
    case type.CHAT_API:
      return {
        ...state,
        chatData: action.payload.data,
      };

    // Payment List
    case type.WALLET_LIST:
      return {
        ...state,
        paymentData: action.payload.data,
      };

    //  Get User Wise Bet List
    case type.USER_WISE_BET_LIST:
      return {
        ...state,
        userBetList: action.payload.data,
      };

    // Get Call Request List
    case type.CALL_REQUEST_LIST:
      return {
        ...state,
        callReqList: action.payload.data,
      };

    // Get Balance Details
    case type.BALANCE_REPORT:
      return {
        ...state,
        BalanceReport: action.payload.data,
      };

    default:
      return state;
  }
};

export default adminReducer;
