import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Index from "../../../../../component/Index";
import {
  addEditGameBet,
  getAllGameList,
} from "../../../../../redux/auth/action";
import { useEffect } from "react";
import ErrorShow from "../../../../../component/common/ErrorShow";
import { ToastContainer } from "react-toastify";

const AddGameBet = () => {
  const params = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({});

  const AllGameList = useSelector((state) => {
    return state.adminReducer.AllGames;
  });

  const token = useSelector((state) => {
    return state.adminReducer.Token;
  });

  const getAllGameDetail = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("gameId", "");
    dispatch(getAllGameList(urlencoded, navigate));
  };

  useEffect(() => {
    getAllGameDetail();
  }, []);

  const submit = (data) => {
    if (data.betAmount !== 0) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("gameId", data.gameName);
      urlencoded.append("betAmount", data.betAmount);
      // urlencoded.append("winningPrize", data.winningPrice);
      dispatch(addEditGameBet(urlencoded, token, navigate));
    }
  };

  return (
    <div>
      <ToastContainer />
      <Index.Box className="add-user-modal-inner-main modal-inner">
        <Index.Box className="modal-header">
          <Index.Typography
            id="modal-modal-title"
            className="modal-title"
            variant="h6"
            component="h2"
          >
            {params.slug.charAt(0).toUpperCase() + params.slug.slice(1)} Bet
          </Index.Typography>
        </Index.Box>
        <form className="form-content" onSubmit={handleSubmit(submit)}>
          <Index.Box className="dropdown-box input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Select Game
            </Index.FormHelperText>
            <Index.FormControl className="form-control">
              <Controller
                name="gameName"
                control={control}
                {...register("gameName", {
                  required: "Please select Game",
                })}
                render={({ field: { onChange, value } }) => (
                  <Index.Select
                    sx={{
                      "& .MuiFormHelperText-root": {
                        ml: 0,
                        mt: "5px",
                      },
                    }}
                    //defaultValue={props.row ? props.row.cuisine : ""}
                    labelId="gameName"
                    error={Boolean(errors.gameName)}
                    className="dropdown-select"
                    id="gameName"
                    value={value}
                    onChange={onChange}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {AllGameList?.map((data) => {
                      return (
                        <Index.MenuItem value={data?._id}>
                          {data.gameName}
                        </Index.MenuItem>
                      );
                    })}
                  </Index.Select>
                )}
              />
              {/* <span><img src={Svg.greydown} className="grey-down pay-down" alt='grey down arrow'></img></span> */}
            </Index.FormControl>

            <ErrorShow error={errors.gameName?.message} />
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Bet Amount
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                type="number"
                name="betAmount"
                {...register("betAmount", {
                  required: "Bet Amount  is required.",
                  pattern: {
                    value: /^[1-9][0-9]*$/,
                    message: "Please enter a valid Bet Amount",
                  },
                  validate: {
                    lessThanTwo: (value) =>
                      value.length >= 2 ||
                      "Bet amount must be at least 2 digit",
                    moreThanFive: (value) =>
                      value.length <= 5 || "Bet Amount cannot exceed 5 digits",
                  },
                })}
                // InputProps={{
                //   inputProps: { min: 0 },
                // }}

                error={Boolean(errors.betAmount)}
                helperText={errors?.betAmount?.message}
              />
            </Index.Box>
          </Index.Box>
          {/* <Index.Box className="input-box modal-input-box">
          <Index.FormHelperText className="form-lable">
            Winning Price
          </Index.FormHelperText>
          <Index.Box className="form-group">
            <Index.TextField
              fullWidth
              id="fullWidth"
              className="form-control"
              {...register("winningPrice", {
                required: "Winning Price  is required.",
              })}
              error={Boolean(errors.winningPrice)}
              helperText={errors?.winningPrice?.message}
            />
          </Index.Box>
        </Index.Box> */}

          <Index.Box className="modal-user-btn-flex">
            <Index.Box className="discard-btn-main border-btn-main">
              <Index.Button
                className="discard-user-btn border-btn"
                onClick={() => navigate(`/admin/game-wise-bet`)}
              >
                Discard
              </Index.Button>
            </Index.Box>
            <Index.Box className="save-btn-main border-btn-main">
              <Index.Button className="save-user-btn border-btn" type="submit">
                <img src={Index.Svg.save} className="user-save-icon"></img>Save
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </form>
      </Index.Box>
    </div>
  );
};

export default AddGameBet;
